// const _ = require('lodash');
// const pluralize = require('pluralize')
import { plural } from "pluralize"

import _includes from "lodash/includes"
import _split from "lodash/split"
import _isUndefined  from 'lodash/isUndefined'
import _join from "lodash/join"
import _upperCase from "lodash/upperCase"
import _capitalize from "lodash/capitalize"

const splitUrlToParts = function(url){
    return url.split('/');
};

const getAgeDesc = function(url){
    var age_array = _split(splitUrlToParts(url)[3], "-");
    var ageDesc = '', temp = [];

    if(_includes(age_array, 'years') && ( _includes(age_array, 'months') || _includes(age_array, 'month'))){
        ageDesc = age_array[0] + ' years & ' + age_array[2] + ' ' + age_array[3] + ' old';
    }else if(_includes(age_array, 'week')){
        temp = _join(age_array, ' ');
        ageDesc = temp;
    }else if(_includes(age_array, 'month')){
        temp = _join(age_array, ' ');
        ageDesc = temp;
    }else if(_includes(age_array, 'year') || _includes(age_array, 'years')){
        temp = _join(age_array, ' ');
        ageDesc = temp ;
    }

    return ageDesc;
};

// const getLMBlurb = function(){
//     return 'LifeMeasure has a range of charts and calculators that measure the body, mind and human experience from birth to beyond.';
// };

const getLifeStage = function(url){
    var age_array = _split(splitUrlToParts(url)[3], "-");
    var ageGroup = '', temp = 0;

    if(_includes(age_array, 'years') && ( _includes(age_array, 'months') || _includes(age_array, 'month'))){
        temp = parseInt(age_array[0]);
        if(temp === 2){
            ageGroup = 'Toddler';
        }else if(temp === 3 || temp === 4){
            ageGroup = 'Preschooler';
        }else if(temp > 4 && temp < 13) {
            ageGroup = 'Child';
        }else{
            ageGroup = 'Teenager';
        }
    }else if(_includes(age_array, 'week')){
        ageGroup = 'baby';
    }else if(_includes(age_array, 'month')){
        temp = age_array[0];
        if(parseInt(temp) >= 4 && parseInt(temp) <= 11) {
            ageGroup = 'baby';
        }else if(parseInt(temp) >= 13 && parseInt(temp) <= 35) {
            ageGroup = 'Toddler';
        }
    }else if(_includes(age_array, 'year') || _includes(age_array, 'years')){
        temp = parseInt(age_array[0]);
        if(temp === 1 || temp === 2 || temp === 3){
            ageGroup = 'Toddler';
        }else if(temp === 4){
            ageGroup = 'Preschooler';
        }else if(temp < 13){
            ageGroup = 'Child';
        }else{
            ageGroup = 'Teenager';
        }
    }
    return ageGroup;
};

const getChartType = function(url){
    var url_fragment = _split(splitUrlToParts(url)[1], "-")[0];
    var chart_type = '';

    if(!_isUndefined(url_fragment)){
        if(url_fragment === 'bmi'){
            url_fragment = _upperCase(url_fragment);
        }else {
            url_fragment = _capitalize(url_fragment);
        }
        chart_type = url_fragment;
    }else{
        chart_type = 'weight';
    }

    return chart_type;
};

const getGender = function(url){
    var url_fragment = splitUrlToParts(url)[2];
    var gender = '';

    switch (url_fragment){
        case 'boys':
            gender = 'boys';
            break;
        case 'girls':
            gender = 'girls';
            break;
        default:
            gender = 'boys';
            break;
    }
    return gender;
};

const getPageH1Title = function(url){
    var pageH1Title =  _capitalize(getGender(url)) + ' ' + (getChartType(url)) +' chart for ' +
                        plural(getLifeStage(url));

    return pageH1Title;
};

const getPageDataH1Title = function(url){
    var pageH1Title =  _capitalize(getGender(url)) + ' ' + (getChartType(url)) + ' chart '

    return pageH1Title;
};

const getPageH2Title = function(url){
    var pageH2Title = _capitalize(getGender(url)) + ' ' + (getChartType(url)) + ' | ' + getAgeDesc(url) ;
    return pageH2Title;
};

const getPageH1SubTitle = function(url){
    var pageSubTitle = getAgeDesc(url);
    return pageSubTitle;
};

const getPageDataH1SubTitle = function(url){
    var pageSubTitle = ' for ' +
    plural(getLifeStage(url)) + " " + getAgeDesc(url);
    return pageSubTitle;
};

const returnPageVariableHash = function(url){
    return {
        pageH1Title: getPageH1Title(url),
        pageH1SubTitle: getPageH1SubTitle(url),
        pageH2Title: getPageH2Title(url),
        pageDataH1Title: getPageDataH1Title(url),
        pageDataH1SubTitle: getPageDataH1SubTitle(url)
    };
};

export {
    returnPageVariableHash, getAgeDesc, getLifeStage
}
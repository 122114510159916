const _range = require('lodash/range');
const _forEach = require('lodash/forEach');

const babyIntermediaryUrls = function(chartType, gender){
    if(chartType === 'bmi-charts'){
        return {};
    }
    
    return {
        '0 Week old': '/'+chartType+'/'+gender+'/0-week-old-baby',
        '1 Week old': '/'+chartType+'/'+gender+'/1-week-old-baby',
        '2 Week old': '/'+chartType+'/'+gender+'/2-week-old-baby',
        '3 Week old': '/'+chartType+'/'+gender+'/3-week-old-baby',
        '4 Week old': '/'+chartType+'/'+gender+'/4-week-old-baby',
        '5 Week old': '/'+chartType+'/'+gender+'/5-week-old-baby',
        '6 Week old': '/'+chartType+'/'+gender+'/6-week-old-baby',
        '7 Week old': '/'+chartType+'/'+gender+'/7-week-old-baby',
        '8 Week old': '/'+chartType+'/'+gender+'/8-week-old-baby',
        '9 Week old': '/'+chartType+'/'+gender+'/9-week-old-baby',
        '10 Week old': '/'+chartType+'/'+gender+'/10-week-old-baby',
        '11 Week old': '/'+chartType+'/'+gender+'/11-week-old-baby',
        '12 Week old': '/'+chartType+'/'+gender+'/12-week-old-baby',
        '4 Month old': '/'+chartType+'/'+gender+'/4-month-old-baby',
        '5 Month old': '/'+chartType+'/'+gender+'/5-month-old-baby',
        '6 Month old': '/'+chartType+'/'+gender+'/6-month-old-baby',
        '7 Month old': '/'+chartType+'/'+gender+'/7-month-old-baby',
        '8 Month old': '/'+chartType+'/'+gender+'/8-month-old-baby',
        '9 Month old': '/'+chartType+'/'+gender+'/9-month-old-baby',
        '10 Month old': '/'+chartType+'/'+gender+'/10-month-old-baby',
        '11 Month old': '/'+chartType+'/'+gender+'/11-month-old-baby',
        '1 Year old': '/'+chartType+'/'+gender+'/1-year-old-baby',
        '13 Month old': '/'+chartType+'/'+gender+'/13-month-old',
        '14 Month old': '/'+chartType+'/'+gender+'/14-month-old',
        '15 Month old': '/'+chartType+'/'+gender+'/15-month-old',
        '16 Month old': '/'+chartType+'/'+gender+'/16-month-old',
        '17 Month old': '/'+chartType+'/'+gender+'/17-month-old',
        '18 Month old': '/'+chartType+'/'+gender+'/18-month-old',
        '19 Month old': '/'+chartType+'/'+gender+'/19-month-old',
        '20 Month old': '/'+chartType+'/'+gender+'/20-month-old',
        '21 Month old': '/'+chartType+'/'+gender+'/21-month-old',
        '22 Month old': '/'+chartType+'/'+gender+'/22-month-old',
        '23 Month old': '/'+chartType+'/'+gender+'/23-month-old',
        '24 Month old': '/'+chartType+'/'+gender+'/2-year-old'
    }
};

const toddlerIntermediaryUrls = function(chartType, gender){
    if(chartType === 'head-charts'){
        return {};
    }
    return {
        '2 Year old': '/'+chartType+'/'+gender+'/2-year-old',
        '2 Years 1 month old': '/'+chartType+'/'+gender+'/2-years-1-month',
        '2 Years 2 months old': '/'+chartType+'/'+gender+'/2-years-2-months',
        '2 Years 3 months old': '/'+chartType+'/'+gender+'/2-years-3-months',
        '2 Years 4 months old': '/'+chartType+'/'+gender+'/2-years-4-months',
        '2 Years 5 months old': '/'+chartType+'/'+gender+'/2-years-5-months',
        '2 and half': '/'+chartType+'/'+gender+'/2-years-6-months',
        '2 Years 7 months old': '/'+chartType+'/'+gender+'/2-years-7-months',
        '2 Years 8 months old': '/'+chartType+'/'+gender+'/2-years-8-months',
        '2 Years 9 months old': '/'+chartType+'/'+gender+'/2-years-9-months',
        '2  Years 10 months  old': '/'+chartType+'/'+gender+'/2-years-10-months',
        '2  Years 11 months  old': '/'+chartType+'/'+gender+'/2-years-11-months'
    }
};

const preSchoolerIntermediaryUrls = function(chartType, gender){
    if(chartType === 'head-charts'){
        return {};
    }
    return {
        '3 Year old': '/'+chartType+'/'+gender+'/3-years-old',
        '3 Years 1 month old': '/'+chartType+'/'+gender+'/3-years-1-month',
        '3 Years 2 months old': '/'+chartType+'/'+gender+'/3-years-2-months',
        '3 Years 3 months old': '/'+chartType+'/'+gender+'/3-years-3-months',
        '3 Years 4 months old': '/'+chartType+'/'+gender+'/3-years-4-months',
        '3 Years 5 months old': '/'+chartType+'/'+gender+'/3-years-5-months',
        '3 and half': '/'+chartType+'/'+gender+'/3-years-6-months',
        '3 Years 7 months old': '/'+chartType+'/'+gender+'/3-years-7-months',
        '3 Years 8 months old': '/'+chartType+'/'+gender+'/3-years-8-months',
        '3 Years 9 months old': '/'+chartType+'/'+gender+'/3-years-9-months',
        '3 Years 10 months old': '/'+chartType+'/'+gender+'/3-years-10-months',
        '3 Years 11 months old': '/'+chartType+'/'+gender+'/3-years-11-months',
        '4 Year old': '/'+chartType+'/'+gender+'/4-years-old',
        '4 Years 1 month old': '/'+chartType+'/'+gender+'/4-years-1-month',
        '4 Years 2 months old': '/'+chartType+'/'+gender+'/4-years-2-months',
        '4 Years 3 months old': '/'+chartType+'/'+gender+'/4-years-3-months',
        '4 Years 4 months old': '/'+chartType+'/'+gender+'/4-years-4-months',
        '4 Years 5 months old': '/'+chartType+'/'+gender+'/4-years-5-months',
        '4 and half': '/'+chartType+'/'+gender+'/4-years-6-months',
        '4 Years 7 months old': '/'+chartType+'/'+gender+'/4-years-7-months',
        '4 Years 8 months old': '/'+chartType+'/'+gender+'/4-years-8-months',
        '4 Years 9 months old': '/'+chartType+'/'+gender+'/4-years-9-months',
        '4 Years 10 months old': '/'+chartType+'/'+gender+'/4-years-10-months',
        '4 Years 11 months old': '/'+chartType+'/'+gender+'/4-years-11-months'
    }
};

const childIntermediaryUrls = function(chartType, gender){
    if(chartType === 'head-charts'){
        return {};
    }
    var age_array = _range(5, 13);
    var child_urls = {};
    _forEach(age_array, function(age){
        child_urls[age + ' year old'] = '/'+chartType+'/'+gender+'/'+age+ '-years-old';
        child_urls[age + ' years 1 month'] = '/'+chartType+'/'+gender+'/'+age+'-years-1-month';
        child_urls[age + ' years 2 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-2-months';
        child_urls[age + ' years 3 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-3-months';
        child_urls[age + ' years 4 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-4-months';
        child_urls[age + ' years 5 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-5-months';
        child_urls[age + ' and half'] = '/'+chartType+'/'+gender+'/'+age+'-years-6-months';
        child_urls[age + ' years 7 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-7-months';
        child_urls[age + ' years 8 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-8-months';
        child_urls[age + ' years 9 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-9-months';
        child_urls[age + ' years 10 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-10-months';
        child_urls[age + ' years 11 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-11-months';
    });
    // console.log("what is child urls ", child_urls);
    return child_urls
};

const teenaagerIntermediaryUrls = function(chartType, gender){
    if(chartType === 'head-charts'){
        return {};
    }
    var age_array = _range(13, 21);
    var child_urls = {};
    _forEach(age_array, function(age){
        // For the year 20, only include the URL for the exact year
        if (age === 20) {
            child_urls[age + ' year old'] = '/'+chartType+'/'+gender+'/'+age+ '-years-old';
        } else {
            // For other years, include the URLs with additional months
            child_urls[age + ' year old'] = '/'+chartType+'/'+gender+'/'+age+ '-years-old';
            child_urls[age + ' years 1 month'] = '/'+chartType+'/'+gender+'/'+age+'-years-1-month';
            child_urls[age + ' years 2 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-2-months';
            child_urls[age + ' years 3 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-3-months';
            child_urls[age + ' years 4 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-4-months';
            child_urls[age + ' years 5 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-5-months';
            child_urls[age + ' and half'] = '/'+chartType+'/'+gender+'/'+age+'-years-6-months';
            child_urls[age + ' years 7 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-7-months';
            child_urls[age + ' years 8 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-8-months';
            child_urls[age + ' years 9 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-9-months';
            child_urls[age + ' years 10 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-10-months';
            child_urls[age + ' years 11 months'] = '/'+chartType+'/'+gender+'/'+age+'-years-11-months';
        }
    });
    return child_urls
};
module.exports = {
    babyIntermediaryUrls: babyIntermediaryUrls,
    toddlerIntermediaryUrls: toddlerIntermediaryUrls,
    preSchoolerIntermediaryUrls: preSchoolerIntermediaryUrls,
    childIntermediaryUrls: childIntermediaryUrls,
    teenaagerIntermediaryUrls: teenaagerIntermediaryUrls
}
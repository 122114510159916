// const _ = require('lodash');
import _capitalize from "lodash/capitalize"
import _split from "lodash/split"

const urlMap = {
    "height-charts": "Height-Charts",
    "weight-charts": "Weight-Charts",
    "bmi-charts": "BMI-Charts",
    "head-charts": "Head-Charts",
}

const localStorageMap = {
    "head-charts": "lm_head_data",
    "height-charts": "lm_heigh_data",
    "weight-charts": "lm_weight_data",
    "bmi-charts": "lm_bmi_data",
}

const formulateGenderAndChartType = function(hashArray){
    var chartType = hashArray[1];
    var gender = parseUrlForGenderWithString(hashArray);
    if(chartType === 'bmi-charts'){
        return  gender + ' BMI';
    }else{
        return  gender + ' ' + _capitalize(_split(chartType,'-')[0]);
    }
}

const parseUrlForGenderWithString = function(hashArray){
    var url_fragment = hashArray[2];
    var gender = '';

    switch (url_fragment){
        case 'boys':
            gender = 'Boys';
            break;
        case 'girls':
            gender = 'Girls';
            break;
        default:
            gender = 'Boys';
            break;
    }
    return gender;
}

const parseGenderForAlternativeLink= function(hashArray){
    var url_fragment = hashArray[2];
    var gender = '';

    switch (url_fragment){
        case 'boys':
            gender = 'girls';
            break;
        case 'girls':
            gender = 'boys';
            break;
        default:
            gender = 'boys';
            break;
    }
    return gender;
}

const enablePrimaryLink = function(age_key, age_value){
    return (/week|baby|year-old|years-old|old-baby|[1-2][0-9]-month-old/.test(age_value)) || (/half/.test(age_key));
}

export {
    urlMap, formulateGenderAndChartType, parseUrlForGenderWithString, enablePrimaryLink, localStorageMap, parseGenderForAlternativeLink
}